import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  applyFilterShop: ["payload"],
  errorFilterShop: ["error"],
});

export const FilterShopActionCreators = Creators;

const initialState = {
  loading: false,
  filterValues: {
    categories: "",
    color: "",
    size: "",
  },
  error: "",
};

const applyFilterShop = (state = initialState, action) => {
  
  const { sortType, sortValue } = action.payload;
  let filterValues;
  if (sortValue === "clean") {
    filterValues = {
      categories: "",
      color: "",
      size: "",
    };
  } else {
    filterValues = { ...state.filterValues, [sortType]: sortValue };
  }
 
  return {
    ...state,
    loading: false,
    filterValues,
  };
};

const errorFilterShop = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  filterValues: {
    categories: "",
    color: "",
    size: "",
  },
});

export default createReducer(initialState, {
  [Types.APPLY_FILTER_SHOP]: applyFilterShop,
  [Types.ERROR_FILTER_SHOP]: errorFilterShop,
});