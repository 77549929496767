import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
// home pages
const Home = lazy(() => import("./pages/home/Home"));
const Exhibitions = lazy(() => import("./pages/exhibitions"));
const Biography = lazy(() => import("./pages/biography"));
const Product = lazy(() => import("./pages/shop-product/Product"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Studio = lazy(() => import("./pages/Studio"));

const SuccessComponent = lazy(() => import("./pages/SuccessComponent"));
const PendingComponent = lazy(() => import("./pages/PendingComponent"));
const FailureComponent = lazy(() => import("./pages/FailureComponent"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={Home}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/obras"}
                  component={ShopGridFilter}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/exhibitions"}
                  component={Exhibitions}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/biography"}
                  component={Biography}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/studio"}
                  component={Studio}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/product/:id`}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                <Route exact path="/success" component={SuccessComponent} />
                <Route exact path="/pending" component={PendingComponent} />
                <Route exact path="/failure" component={FailureComponent} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
