import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
	requestProducts: [],
	successfulProducts: ['payload'],
	errorProducts: ['error'],
	setProductsSearchText: ['searchText'],
	setSelectedProduct: ['productId'],
	setNewProduct: [],
	requestSaveProduct: [],
	successfulSaveProduct: ['payload'],
	errorSaveProduct: ['error'],
	requestDeleteProduct: [],
	successfulDeleteProduct: ['productId'],
	errorDeleteProduct: ['error'],
	requestUpdateProduct: [],
	successfulUpdateProduct: ['payload'],
	errorUpdateProduct: ['error']
});

export const ProductsActionCreators = Creators;

const initialState = {
	loading: false,
	products: [],
	error: '',
	selectedProduct: {},
	searchText: '',
	message: ''
};

const requestProducts = (state = initialState) => ({
	...state,
	loading: true,
	error: '',
	products: []
});

const successfulProducts = (state = initialState, action) => {
	const { message, products } = action.payload.result;
	return {
		...state,
		loading: false,
		message,
		products: products.reverse()
	};
};

const errorProducts = (state = initialState, action) => ({
	...state,
	loading: false,
	error: action.error,
	products: null
});

export default createReducer(initialState, {
	[Types.REQUEST_PRODUCTS]: requestProducts,
	[Types.SUCCESSFUL_PRODUCTS]: successfulProducts,
	[Types.ERROR_PRODUCTS]: errorProducts,
});